import React from "react"
import { Helmet } from "react-helmet"

interface ISeo {
  siteMeta: ISiteMeta
}

const SEO: React.VFC<ISeo> = ({siteMeta}) => {
  const {
    title,
    shortName,
    description,
    siteUrl,
    image,
    siteLanguage,
    siteLocale,
    authorName,
    favicon,
    backgroundColor,
    themeColor,
    twitterUsername,
    googleTag
  } = siteMeta;

  return (
    <Helmet title={title} htmlAttributes={{
      lang: 'en',
    }}>
      <meta name="description" content={description} />
      <meta name="application-name" content={shortName} />
      <meta name="image" content={image} />
      <meta name="author" content={authorName}/>
      <meta name="theme-color" content={themeColor}/>
      {siteUrl && <link rel="canonical" href={siteUrl} />}

      {siteUrl && <meta property="og:url" content={siteUrl} />}
      {<meta property="og:type" content="article" />}
      {title && <meta property="og:title" content={title} />}
      {description && (
        <meta property="og:description" content={description} />
      )}
      {image && <meta property="og:image" content={image} />}

      <meta name="twitter:card" content="summary_large_image" />
      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}
      {title && <meta name="twitter:title" content={title} />}
      {description && (
        <meta name="twitter:description" content={description} />
      )}
      {image && <meta name="twitter:image" content={image} />}

      {googleTag && <script async src={`https://www.googletagmanager.com/gtag/js?id=${googleTag}`}/>}
      {googleTag && <script>{`window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments)}
        gtag('js', new Date());
        gtag('config', '${googleTag}');`}</script>}
    </Helmet>
  )
}

export default SEO;
