import React from "react"
import {createGlobalStyle} from "styled-components"

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #111;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 0.875rem;
    line-height: 1.2em;
    color: #fff;

    margin: 0;
    min-height: 100%;
  }
  
  * {
    box-sizing: border-box;
  }
  
  p {
    margin-bottom: 0;
  }
  
  h1 {
    margin: 0;
  }
  
  a {
    color: #fff;
    text-decoration: none;
  }
`

interface ILayoutProps {
  children: React.ReactNode
}

const Layout: React.VFC<ILayoutProps> = ({children}) => {
  return (
    <>
      <GlobalStyle/>
      {children}
    </>
  )
}

export default Layout;
